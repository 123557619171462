import { translate } from '@/core/localization/translate';
export const {
  selectedProductTitle,
  suggestedProductTitle,
  promotedProductTitle,
  recentlyTitle,
  ariaLabelPrevious,
  ariaLabelNext
} = translate({
  "selectedProductTitle": "La nostra selezione",
  "suggestedProductTitle": "I nostri bestseller",
  "promotedProductTitle": "Approfitta delle promo!",
  "recentlyTitle": "Prodotti visti di recente",
  "ariaLabelPrevious": "Precedente",
  "ariaLabelNext": "Seguente"
});