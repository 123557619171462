import { all, call, put, takeLatest } from 'typed-redux-saga';

import { waitForAndGetFlagValuesSaga } from '@/core/flags/sagas/waitForAndGetFlagValues.saga';
import { type BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { fetchHomepageTertiaryCampaignsGql } from '@/productDiscovery/CommercialAnimation/services/fetchHomepageTertiaryCampaigns';
import { bannerCampaignGqlProcessor } from '@/productDiscovery/CommercialAnimation/services/processor';
import { abTestAndFeatureFlagValidated } from '@/productDiscovery/CommercialAnimation/utils/abTestAndFeatureFlagValidated';
import { getUserSegments } from '@/productDiscovery/CommercialAnimation/utils/getUserSegments';
import {
  GET_HOMEPAGE_TERTIARY_CAMPAIGNS,
  GET_HOMEPAGE_TERTIARY_CAMPAIGNS_FAILURE,
  GET_HOMEPAGE_TERTIARY_CAMPAIGNS_SUCCESS,
} from '@/retention/Homepage/Homepage.actions';

function* getHomepageTertiarySaga() {
  try {
    const userSegments = yield* call(getUserSegments);

    const { flags, response } = yield* all({
      flags: call(waitForAndGetFlagValuesSaga),
      response: call(fetchHomepageTertiaryCampaignsGql, userSegments),
    });

    const responseFromApi: any = response;
    const processedHomepageTertiaryCampaigns =
      responseFromApi.homepageTertiary.length > 0
        ? responseFromApi.homepageTertiary
            .filter((campaign: BannerCampaign) =>
              abTestAndFeatureFlagValidated(campaign, flags),
            )
            .map(bannerCampaignGqlProcessor)
        : null;

    yield* put({
      type: GET_HOMEPAGE_TERTIARY_CAMPAIGNS_SUCCESS,
      data: processedHomepageTertiaryCampaigns,
    });
  } catch (error) {
    yield* put({
      type: GET_HOMEPAGE_TERTIARY_CAMPAIGNS_FAILURE,
      error: error.response?.data?.error?.app_code || error.message,
    });
  }
}

export function* rootSaga() {
  yield* takeLatest(GET_HOMEPAGE_TERTIARY_CAMPAIGNS, getHomepageTertiarySaga);
}
