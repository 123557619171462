import classnames from 'classnames';
import type { FunctionComponent } from 'react';

import { BannerCampaignAdvertiserLabel } from '@/domains/productDiscovery/CommercialAnimation/components/AdvertiserInformation/BannerCampaignAdvertiserLabel';
import { CampaignImage } from '@/productDiscovery/CommercialAnimation/components/CampaignImage/CampaignImage';
import { useHomepageTertiaryCampaigns } from '@/productDiscovery/CommercialAnimation/hooks/useHomepageTertiaryCampaigns';

import styles from './HomepageTertiaryCampaigns.module.scss';

const SIZES = {
  small: {
    ldpi: {
      width: 328,
      height: 100,
    },
    hdpi: {
      width: 656,
      height: 200,
    },
  },
  medium: {
    ldpi: {
      width: 320,
      height: 98,
    },
    hdpi: {
      width: 640,
      height: 196,
    },
  },
  large: {
    ldpi: {
      width: 450,
      height: 142,
    },
    hdpi: {
      width: 900,
      height: 284,
    },
  },
};

interface HomepageTertiaryCampaignsProps {
  className?: string;
  loading?: 'eager' | 'lazy';
}

export const HomepageTertiaryCampaigns: FunctionComponent<
  HomepageTertiaryCampaignsProps
> = ({ className, loading }) => {
  const tertiaryCampaigns = useHomepageTertiaryCampaigns();

  if (!tertiaryCampaigns || tertiaryCampaigns.length < 1) {
    return null;
  }

  const containerClassname = classnames(styles.container, className);

  return (
    <div className={containerClassname}>
      {tertiaryCampaigns[0] &&
        tertiaryCampaigns[0].image &&
        tertiaryCampaigns[0].link && (
          <div className={styles.campaignContainer}>
            {(tertiaryCampaigns[0].sponsor ||
              tertiaryCampaigns[0].advertiser) && (
              <BannerCampaignAdvertiserLabel
                sponsor={tertiaryCampaigns[0].sponsor}
                advertiser={tertiaryCampaigns[0].advertiser}
                userSegments={tertiaryCampaigns[0].userSegments}
                spaceSlug="tertiary_campaigns"
              />
            )}

            <CampaignImage
              imageDesktopUrl={tertiaryCampaigns[0].image.desktopUrl || ''}
              imageMobileUrl={tertiaryCampaigns[0].image.mobileUrl || ''}
              altText={tertiaryCampaigns[0].image.altText}
              sizes={SIZES}
              imgClassName={styles.tertiaryCampaign}
              dataTestId="HomepageTertiary"
              link={tertiaryCampaigns[0].link}
              type="tertiary"
              bannerCampaign={tertiaryCampaigns[0]}
              position={0}
              loading={loading}
            />
          </div>
        )}
      {tertiaryCampaigns[1] &&
        tertiaryCampaigns[1].image &&
        tertiaryCampaigns[1].link && (
          <div className={styles.campaignContainer}>
            {(tertiaryCampaigns[1].sponsor ||
              tertiaryCampaigns[1].advertiser) && (
              <BannerCampaignAdvertiserLabel
                sponsor={tertiaryCampaigns[1].sponsor}
                advertiser={tertiaryCampaigns[1].advertiser}
                userSegments={tertiaryCampaigns[1].userSegments}
                spaceSlug="tertiary_campaigns"
              />
            )}
            <CampaignImage
              imageDesktopUrl={tertiaryCampaigns[1].image.desktopUrl || ''}
              imageMobileUrl={tertiaryCampaigns[1].image.mobileUrl || ''}
              altText={tertiaryCampaigns[1].image.altText}
              sizes={SIZES}
              imgClassName={styles.tertiaryCampaign}
              dataTestId="HomepageTertiary"
              link={tertiaryCampaigns[1].link}
              type="tertiary"
              bannerCampaign={tertiaryCampaigns[1]}
              position={1}
              loading={loading}
            />
          </div>
        )}
    </div>
  );
};
