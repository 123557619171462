import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDynamicSaga } from '@/core/applicationState/hooks/useDynamicSaga';
import { authSelector } from '@/customerManagement/customerManagement.selectors';
import { rootSaga } from '@/productDiscovery/CommercialAnimation/HomepageSecondary.saga';
import {
  useFetchedSecondary,
  useHomepageSecondary,
} from '@/productDiscovery/CommercialAnimation/hooks/useHomepageSecondary';
import { getHomepageSecondaryCampaigns } from '@/retention/Homepage/Homepage.actions';

export const useHomepageSecondaryCampaigns = () => {
  useDynamicSaga('homepageSecondaryCampaigns', rootSaga);
  const secondaryCampaigns = useHomepageSecondary();
  const fetched = useFetchedSecondary();

  const auth = useSelector(authSelector);
  const { hasRetrievedCustomerSegments } = auth;

  const dispatch = useDispatch();

  useEffect(() => {
    if (hasRetrievedCustomerSegments && !secondaryCampaigns && !fetched) {
      dispatch(getHomepageSecondaryCampaigns());
    }
  }, [dispatch, hasRetrievedCustomerSegments, secondaryCampaigns, fetched]);

  return { secondaryCampaigns, fetched };
};
